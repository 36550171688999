import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Options from "../../orders/options";
import { ScheduleModalPresenter } from "./ScheduleModal-presenter";

function ScheduleModal(props: any) {
  const scheduleModalPresenter = new ScheduleModalPresenter();
  const {
    rowData,
    rowindex,
    checkBoxIds,
    handleCheckBoxChange,
    handleSaveModal,
    tableData,
    isSaving,
    selectedLine,
    handleChangeLockState,
    handleDeleteReservation
  } = props;
  const dropDownValues = scheduleModalPresenter.statusDropDownValues;
  const checkboxOptions = scheduleModalPresenter.statusCheckboxOptions;
  const [inputField, setInputField] = React.useState({
    keyIndex: "",
    rowindex: "",
    isInputField: false,
  });
  const [editedVal, setEditedVal] = React.useState("");
  const [selectedDropDown, setSelectedDropDown] = React.useState({
    keyIndex: "",
    rowindex: "",
    value: "",
  });
  const [isRowLocked, setIsRowLocked] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [reservationVal, setReservationVal] = useState("");

  const [textValues, setTextValues] = useState({
    with: "",
    "insert-above": "",
    "insert-below": "",
  });
  const handleInputField = (keyIndex: any, rowindex: any) => {
    setInputField({
      keyIndex,
      rowindex: rowindex + 1,
      isInputField: true,
    });
  };
  const handleInputData = (keyIndex: any, rowindex: any, event: any) => {
    setEditedVal(event.target.value);
    const rowIndex = Number(rowindex);
    const rowKeyIndex = Number(keyIndex);
    rowData[rowindex + 1].cells[keyIndex].text = event.target.value;
    //Update the Main data
    if (rowKeyIndex === 11) tableData[rowIndex].Notes = event.target.value;
    else if (rowKeyIndex === 15)
      tableData[rowIndex].ChassisNotes = event.target.value;
    else if (rowKeyIndex === 16)
      tableData[rowIndex].ElectricalSystem = event.target.value;
    else if (rowKeyIndex === 17)
      tableData[rowIndex].UpgradePack = event.target.value;
    else if (rowKeyIndex === 18)
      tableData[rowIndex].ProductionValue = event.target.value;
    else if (rowKeyIndex === 19)
      tableData[rowIndex].DrawnBy = event.target.value;
  };
  const handleDropDownChange = (keyIndex: any, rowindex: any, event: any) => {
    setSelectedDropDown({
      keyIndex,
      rowindex: rowindex + 1,
      value: event.target.value,
    });
    rowData[rowindex + 1].cells[keyIndex].text = event.target.value;
    tableData[Number(rowindex)].Status = event.target.value;
  };
  const handleRadioChange = (event: { target: { value: any } }) => {
    setSelectedOption(event.target.value);
  };
  const handleTextChange = (event: any, option: string) => {
    const { value } = event.target;
    if (selectedOption) {
      setTextValues((prevTextValues) => ({
        ...prevTextValues,
        [option]: value,
      }));
    }
  };
  const handleIsLocked = (key: string, rowindex: number, event: any) => {
    setIsRowLocked(event.target.checked);
    rowData[rowindex + 1].cells[key].rawData = event.target.checked;
    handleChangeLockState(event.target.checked);
  };

  return (
    <div>
      <Modal
        style={{ zIndex: "1054" }}
        {...{ show: props.show, onHide: props.onHide }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row gx-0 gx-lg-2">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <p className="font-sm text-black fw-medium">
                    Add Reservation
                  </p>
                </div>
                <div className="col-6">
                  <select
                    style={{ width: "90%" }}
                    className="font-sm text-black"
                    onChange={(event) => setReservationVal(event.target.value)}
                  >
                    <option
                      className="pb-2"
                      value={tableData[Number(rowindex)].reservationVal}
                    >
                      {tableData[Number(rowindex)].reservationVal
                        ? tableData[Number(rowindex)].reservationVal
                        : "---------select--------"}
                    </option>
                    {scheduleModalPresenter.ReservationDealerOptions.map(
                      (val, i) => (
                        <option className="pb-2" value={val.label} key={i}>
                          {val.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              {Object.keys(rowData[0].cells)
                .slice(2)
                .slice(0, 7)
                .map((key, index) => {
                  return (
                    <div className="row gx-3" key={index}>
                      <div className="col-6">
                        <p className="font-sm text-black fw-medium">
                          {rowData[0].cells[key].text}:
                        </p>
                      </div>
                      <div className="col-6">
                        {rowData[0].cells[key].text === "Status" ? (
                          <select
                            style={{ width: "90%" }}
                            className="font-sm text-black"
                            onChange={(event) =>
                              handleDropDownChange(key, rowindex, event)
                            }
                          >
                            <option
                              className="pb-2"
                              value={rowData[rowindex + 1].cells[key].text}
                            >
                              {rowData[rowindex + 1].cells[key].text
                                ? rowData[rowindex + 1].cells[key].text
                                : "----select----"}
                            </option>
                            {dropDownValues.map((val, i) => (
                              <option
                                className="pb-2"
                                value={val.value}
                                key={i}
                              >
                                {val.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="font-sm text-black">
                            {rowData[rowindex + 1].cells[key].text}
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        <div className="row gy-3">
                          {rowData[0].cells[key].text === "Status" &&
                            rowData[rowindex + 1].cells[key].text ===
                              "Scheduled" && (
                              <>
                                <div className="col-6"></div>
                                <div className="col-6">
                                  {checkboxOptions.map((option, i) => (
                                    <div
                                      className="font-sm text-black d-flex align-items-center gap-2 mb-2"
                                      key={option.id}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checkbox-${option.id}`}
                                        name={`checkbox-${option.id}`}
                                        value={option.label}
                                        onChange={(event) =>
                                          handleCheckBoxChange(event)
                                        }
                                        checked={checkBoxIds.includes(
                                          option.id
                                        )}
                                      />
                                      <label htmlFor={`checkbox-${option.id}`}>
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* swap Option */}
              <div className="row gx-3 mt-2">
                <div className="col-6">
                  <p className="font-sm">Swap :</p>
                </div>
                <div className="col-6">
                  {["With", "Insert above", "Insert below"].map(
                    (option, index) => (
                      <div
                        key={index}
                        className="font-sm text-black d-flex align-items-center gap-2"
                      >
                        <input
                          type="radio"
                          id={`${option.toLowerCase().replace(/\s+/g, "-")}`}
                          name="swap-options"
                          value={option.toLowerCase().charAt(0)}
                          onChange={handleRadioChange}
                        />
                        <label
                          htmlFor={`${option
                            .toLowerCase()
                            .replace(/\s+/g, "-")}-text`}
                        >
                          {option}
                        </label>

                        {index === 0 ? (
                          <input
                            type="text"
                            id={`${option.toLowerCase()}`}
                            placeholder="Serial No"
                            style={{
                              width: "40%",
                              height: Options.length * 20,
                              marginLeft: "40px",
                            }}
                            onChange={(event) =>
                              handleTextChange(event, option.toLowerCase())
                            }
                          />
                        ) : index === 1 ? (
                          <input
                            type="text"
                            id={`${option.toLowerCase().replace(/\s+/g, "-")}`}
                            placeholder="Serial No"
                            style={{
                              width: "40%",
                              height: Options.length * 20,
                              marginLeft: "2px",
                            }}
                            onChange={(event) =>
                              handleTextChange(
                                event,
                                option.toLowerCase().replace(/\s+/g, "-")
                              )
                            }
                          />
                        ) : (
                          <input
                            type="text"
                            id={`${option.toLowerCase().replace(/\s+/g, "-")}`} //insert-below-text
                            placeholder="Serial No"
                            style={{
                              width: "40%",
                              height: Options.length * 20,
                              marginLeft: "4px",
                            }}
                            onChange={(event) =>
                              handleTextChange(
                                event,
                                option.toLowerCase().replace(/\s+/g, "-")
                              )
                            }
                          />
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="col-6">
              {Object.keys(rowData[0].cells)
                .slice(9)
                .map((key, index) => {
                  return (
                    <div className="row gx-3" key={index}>
                      <div className="col-6">
                        <p className="font-sm text-black fw-medium">
                          {rowData[0].cells[key].text}:
                        </p>
                      </div>
                      {rowData[0].cells[key].text === "Lock" ? (
                        <div className="col-6">
                          <input
                            type="checkBox"
                            id="lock-checkbox"
                            checked={rowData[rowindex + 1].cells[key].rawData}
                            onChange={(event) =>
                              handleIsLocked(key, rowindex, event)
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className="col-6"
                          onClick={() => handleInputField(key, rowindex + 1)}
                        >
                          {(rowData[0].cells[key].text === "Notes" ||
                            rowData[0].cells[key].text === "Chassis Notes" ||
                            rowData[0].cells[key].text ===
                              "Electrical System" ||
                            rowData[0].cells[key].text === "Upgrade Pack" ||
                            rowData[0].cells[key].text === "Prod Value" ||
                            rowData[0].cells[key].text === "Drawn By") &&
                          inputField.keyIndex === `${key}` &&
                          inputField.isInputField ? (
                            <input
                              className="font-sm text-black"
                              style={{ width: "100%" }}
                              type="text"
                              value={rowData[rowindex + 1]?.cells[key]?.text}
                              onChange={(event) =>
                                handleInputData(key, rowindex, event)
                              }
                            />
                          ) : (
                            <input
                              className="font-sm text-black"
                              style={{ width: "100%" }}
                              value={rowData[rowindex + 1].cells[key].text}
                              readOnly
                            />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <div>
            {tableData[Number(rowindex)].isReserved && (
              <button className="btn btn-danger" onClick={() => handleDeleteReservation(props.onHide)}>Delete Reservation</button>
            )}
          </div>
          <div className="d-flex gap-3">
            <button className="btn btn-danger" onClick={props.onHide}>
              Close
            </button>
            <button
              className="btn blue-btn"
              onClick={() => {
                handleSaveModal(
                  rowindex,
                  checkBoxIds,
                  textValues,
                  selectedLine,
                  reservationVal,
                  props.onHide
                );
              }}
            >
              {isSaving ? "...Saving" : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ScheduleModal;
