import {
  CellTemplate,
  Column,
  Compatible,
  Id,
  Row,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { ResponseField, CustomCell, AnchorCell } from "./ScheduledFeed-models";
import ScheduledFeedServiceProvider from "./scheduleFeed-services";
import {
  IScheduleGridModel,
  IUpdateScheduleSubStatusModel,
} from "../../../generated/generated-proxies";
import { toast } from "react-toastify";
import AnchorTag from "./Schedule-anchor-tag";

export class ScheduledFeedPresenter {
  private serviceProvider = new ScheduledFeedServiceProvider();
  currentPage = 1;
  pageSize = 10000;

  tableHeaderRow: Row = {
    rowId: "header",
    height: 150,
    cells: [
      { type: "header", text: "" },
      { type: "header", text: "Edit Cell" },
      { type: "header", text: "Week No" },
      { type: "header", text: "Production Start Date" },
      { type: "header", text: "View Order" },
      { type: "header", text: "Chassis No" },
      { type: "header", text: "Cusomter Name" },
      { type: "header", text: "Model" },
      { type: "header", text: "Dealer" },
      { type: "header", text: "Status" },
      { type: "header", text: "Completion Date" },
      { type: "header", text: "Lock" },
      { type: "header", text: "Notes" },
      { type: "header", text: "Weekly Target" },
      { type: "header", text: "Orders Count" },
      { type: "header", text: "Empty Spot" },
      { type: "header", text: "Chassis Notes" },
      { type: "header", text: "Electrical System" },
      { type: "header", text: "Upgrade Pack" },
      { type: "header", text: "Prod Value" },
      { type: "header", text: "Drawn By" },
      { type: "header", text: "Drawn Date" },
      { type: "header", text: "COT's Completion Date" },
    ],
  };

  tableColumns: Column[] = [
    { columnId: "Drag", width: 30 },
    { columnId: "Edit", width: 20 },
    { columnId: "week no", width: 40 },
    { columnId: "productionStartDate", width: 240 },
    { columnId: "link", width: 50 },
    { columnId: "chassisNo", width: 80 },
    { columnId: "customer name", width: 140 },
    { columnId: "model", width: 290 },
    { columnId: "dealer", width: 230 },
    { columnId: "status", width: 150 },
    { columnId: "completiondate", width: 240 },
    { columnId: "lock", width: 30 },
    { columnId: "notes", width: 60 },
    { columnId: "weeklyTarget", width: 40 },
    { columnId: "ordersCount", width: 40 },
    { columnId: "emptySpot", width: 40 },
    { columnId: "cahssisNotes", width: 100 },
    { columnId: "electricalSystem", width: 100 },
    { columnId: "upgradePack", width: 60 },
    { columnId: "productionValue", width: 30 },
    { columnId: "drawnBy", width: 60 },
    { columnId: "drawnDate", width: 110 },
    { columnId: "completion date", width: 115 },
  ];

  AnchorCellTemplate: CellTemplate<AnchorCell> = {
    getCompatibleCell: (
      uncertainCell: Uncertain<AnchorCell>
    ): Compatible<AnchorCell> => {
      const text = getCellProperty(uncertainCell, "text", "string");
      const link = getCellProperty(uncertainCell, "link", "string");
      const value = getCellProperty(uncertainCell, "value", "number");
      return { ...uncertainCell, text, link, value, type: "anchor" };
    },
    handleKeyDown: (
      cell: Compatible<AnchorCell>,
      keyCode: number,
      ctrl: boolean,
      shift: boolean,
      alt: boolean,
      key?: string | undefined
    ) => {
      return { cell, enableEditMode: false };
    },
    update: (
      cell: Compatible<AnchorCell>,
      cellToMerge: Partial<AnchorCell>
    ): Compatible<AnchorCell> => ({
      ...cell,
      ...cellToMerge,
    }),
    getClassName: (): string => "",
    render: (
      cell: Compatible<AnchorCell>,
      isInEditMode: boolean,
      onCellChanged: (cell: Compatible<AnchorCell>, commit: boolean) => void
    ): React.ReactNode => AnchorTag(cell.link, cell.text),
  };

  getRows = (field: ResponseField[]): Row[] => [
    this.tableHeaderRow,
    ...field.map<Row>((res, idx) => ({
      rowId: res.RowId,
      reorderable: true,
      cells: [
        {
          type: "text",
          text: "...",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.WeekNumber ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.ProductionDate ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        // {
        //   type: "email",
        //   text: res.OrderLink ?? "",
        //   style: {
        //     backgroundColor: res.isReserved
        //       ? "#a3d7a3"
        //       : idx % 2 === 0
        //       ? "#fff"
        //       : "#eaeae8",
        //   },
        // },
        {
          type: "anchor",
          text: `${res.OrderLink ? 'Link' : ''}`, // Display text
          link: res.OrderLink ?? "", // Actual URL
          value: 0, // Dummy value
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        } as AnchorCell,
        {
          type: "text",
          text: res.Serial ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.JobContactLastName ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.Model ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.Dealer ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.Status ?? "",
          style: { background: this.checkIsAllOptionSelected(res, idx) },
        },
        {
          type: "text",
          text: res.CompletionDate ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
          className: `${res.isLocked ? "tick" : "cross"}`,
          rawData: res.isLocked,
        },
        {
          type: "text",
          text: res.Notes ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.WeeklyTarget ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.OrdersCount ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.EmptySpot ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text:
            res.ChassisNotes ??
            this.chesisNotesFieldAutoInput(res.Description ?? ""),
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text:
            res.ElectricalSystem ??
            this.electricalSystemFieldAutoInput(res.Description ?? ""),
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text:
            res.UpgradePack ??
            this.upgradePackFieldAutoInput(res.Description ?? ""),
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.ProductionValue ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.DrawnBy ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.DrawnDate ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
        {
          type: "text",
          text: res.PreferredCompletion ?? "",
          style: {
            backgroundColor: res.isReserved
              ? "#a3d7a3"
              : idx % 2 === 0
              ? "#fff"
              : "#eaeae8",
          },
        },
      ] as CustomCell[],
    })),
  ];

  checkIsAllOptionSelected = (row: any, idx: number) => {
    let backgroundColor = row.isReserved
      ? "#a3d7a3"
      : idx % 2 === 0
      ? "#fff"
      : "#eaeae8";
    if (row.status && row.scheduleSubStatus === "Scheduled" && row.scheduleSubStatus?.length < 9) {
      backgroundColor = "#FFADB0";
    }
    return backgroundColor;
  };

  // getLineOptions() {
  //   return [
  //     { value: "main-line", displayText: "Main Line" },
  //     { value: "crv-line", displayText: "Crv Line" },
  //   ];
  // }

  async handleRearrange(selectedOption: string) {
    const response = await this.serviceProvider.rearrangeData(selectedOption);
    return response;
  }

  reorderArray = (arr: ResponseField[], idxs: number[], to: number) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    const targetIdx =
      Math.min(...idxs) < to
        ? to + 1
        : to - idxs.filter((idx) => idx < to).length;
    const leftSide = arr.filter(
      (_, leftArrIdx) => leftArrIdx < targetIdx && !idxs.includes(leftArrIdx)
    );
    const rightSide = arr.filter(
      (_, rightArrayIdx) =>
        rightArrayIdx >= targetIdx && !idxs.includes(rightArrayIdx)
    );
    return [...leftSide, ...movedElements, ...rightSide];
  };

  async reorderDataRowAndSave(
    prevData: ResponseField[],
    targetRowId: Id,
    rowIds: Id[],
    selectedOption: string
  ) {
    const to = prevData.findIndex((item) => item.RowId === targetRowId);
    const rowIndices = rowIds.map((id) =>
      prevData.findIndex((item) => item.RowId === id)
    );
    const newData = this.reorderArray(prevData, rowIndices, to);
    const updated_data = await this.serviceProvider.updateReorderScheduleData(
      selectedOption,
      newData
    );
    return updated_data;
  }

  async sendReorderedDataToDb(
    fromItem: ResponseField[],
    toItem: ResponseField[]
  ) {
    const response = await this.serviceProvider.sendReorderDatatoDb({
      fromItem,
      toItem,
    });
    return response;
  }

  electricalSystemFieldAutoInput = (field: string) => {
    field = field.toLowerCase();
    if (
      field.includes("enerdrive") &&
      field.includes(" i ") &&
      field.includes("400")
    ) {
      return "EN I 400";
    } else if (field.includes("victron")) {
      return "Victron";
    } else if (field.includes("redarc redvision") && field.includes("ilo")) {
      return "Redvision";
    } else if (field.includes("enerdrive 600") && field.includes(" i ")) {
      return "EN I 600";
    } else {
      return "";
    }
  };

  upgradePackFieldAutoInput = (field: string) => {
    field = field.toLowerCase();
    if (field.includes("excalibur") && field.includes("upgrade pack")) {
      return "EXUP";
    } else if (field.includes("all terrain upgrade")) {
      return "AT";
    } else if (field.includes("x country upgrade")) {
      return "XC";
    } else {
      return "";
    }
  };

  chesisNotesFieldAutoInput = (field: string) => {
    field = field.toLowerCase();
    if (field.includes("extreme off road") && field.includes("upgrade pack")) {
      return "Extreme off road";
    } else if (field.includes("xcountry")) {
      return "x countrky";
    } else if (field.includes("hot dipped galvanised")) {
      return "Hot dip Gal";
    } else if (field.includes("upgrade to stage 2 tandem axle airbags")) {
      return "Stage 2 Airbags";
    } else {
      return "";
    }
  };

  async loadLineData(
    selectedValue: string,
    query: string,
    refreshData: boolean
  ) {
    const queryStr = refreshData ? "" : query;
    if (refreshData) await this.triggerDataUpdate();
    return await this.fetchSelectedLineData(selectedValue, queryStr);
  }

  async fetchSelectedLineData(line_view: string, searchText: string) {
    const response = await this.serviceProvider.fetchLineData(
      line_view,
      searchText,
      this.currentPage,
      this.pageSize
    );
    return response;
  }

  triggerDataUpdate() {
    return this.serviceProvider.triggerDataUpdate();
  }

  async updateModalSwapOptions(
    textValues: any,
    rowId: number,
    selectedLine: string,
    fromChassisNo: string
  ) {
    const response = await this.serviceProvider.modalSwapOptionsUpdate(
      textValues,
      rowId,
      selectedLine,
      fromChassisNo
    );
    const data = response.data;
    if (!response.success) toast.error("Chassis No. not found");
    return data;
  }

  idFormatter = (ids: string[]) => ids.join(",");

  range(start: number, end: number) {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }

  getReorderedRows(target: number, movingRowIds: Id[], data: ResponseField[]) {
    let replacedIds = [];
    let oldRows = [];
    let newRows = [];
    const minVal = Math.min(...movingRowIds.map((id) => Number(id)));
    if (target > minVal) {
      //Up to Down
      replacedIds = this.range(target - movingRowIds.length + 1, target);
    } else {
      //Down to Up
      replacedIds = this.range(target, target + (movingRowIds.length - 1));
    }
    oldRows = movingRowIds.map((id) => data[Number(id)]);
    newRows = replacedIds.map((id) => data[Number(id)]);
    return { oldRows, newRows };
  }

  async handleModalDataChange(
    rowData: ResponseField,
    checkBoxIds: string[],
    selectedOption: string,
    reservationVal: string,
    lockState: boolean
  ) {
    const formattedSelectedIdsString = this.idFormatter(checkBoxIds);

    //Variables
    const date = new Date(Date.now());

    //Request Body
    const tableRequestBody: IScheduleGridModel = {
      rowId: rowData.RowId,
      createdDate: date.toISOString(),
      weekNumber: rowData.WeekNumber,
      productionDate: rowData.ProductionDate,
      jobId: rowData.JobID,
      chassisNo: rowData.Serial ?? "",
      customerName: rowData.JobContactLastName ?? "",
      model: rowData.Model ?? "",
      dealer: rowData.Dealer ?? "",
      status: rowData.Status ?? "",
      completionDate: rowData.PreferredCompletion ?? "",
      notes: rowData.Notes ?? "",
      chassisNotes: rowData.ChassisNotes ?? "",
      electricalSystem: rowData.ElectricalSystem ?? "",
      upgradePack: rowData.UpgradePack ?? "",
      productionValue: rowData.ProductionValue ?? "",
      drawnBy: rowData.DrawnBy ?? "",
      drawnDate: date.toISOString(),
      scheduleSubStatus: formattedSelectedIdsString ?? "",
      isLocked: lockState,
      reservationVal: reservationVal ?? "",
      isReserved: reservationVal ? true : false,
    };
    const checkBoxIdRequestBody: IUpdateScheduleSubStatusModel = {
      jobId: rowData.JobID,
      scheduleSubStatus: formattedSelectedIdsString ?? "",
    };

    const modalResponse = await this.serviceProvider.saveScheduleRowData(
      selectedOption,
      tableRequestBody
    );
    await this.serviceProvider.updateModalCheckBoxData(checkBoxIdRequestBody);
    return modalResponse;
  }

  async handleRemoveReserveItem(lineName: string, rowId: number) {
    const response = await this.serviceProvider.removeReservedRow(
      lineName,
      rowId
    );
    return response;
  }
}

let debounceTimer: NodeJS.Timeout;
export const debounce = (func: any, timeout = 100) => {
  return (...args: any) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

const edgeSize = 20;
const edgeTop = 320;
let timer: NodeJS.Timeout;

export const handleDragRow = (event: any) => {
  // Get the viewport-relative coordinates of the mousemove event.
  const mousePointerY = event.clientY;

  // Get the viewport dimensions.
  const viewportHeight = document.documentElement.clientHeight;

  const edgeBottom = viewportHeight - edgeSize;
  const isInTopEdge = mousePointerY < edgeTop;
  const isInBottomEdge = mousePointerY > edgeBottom;

  if (!(isInTopEdge || isInBottomEdge)) {
    clearTimeout(timer);
    return;
  }

  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );

  const maxScrollY = documentHeight - viewportHeight;

  (function checkForWindowScroll() {
    clearTimeout(timer);
    if (adjustWindowScroll()) {
      timer = setTimeout(checkForWindowScroll, 30);
    }
  })();

  // Adjust the window scroll based on the user's mouse position.
  function adjustWindowScroll() {
    // Get the current scroll position of the document.
    const currentScrollX = window.pageXOffset;
    const currentScrollY = window.pageYOffset;

    // Determine if the window can be scrolled in any particular direction.
    const canScrollUp = currentScrollY > 0;
    const canScrollDown = currentScrollY < maxScrollY;

    let nextScrollX = currentScrollX;
    let nextScrollY = currentScrollY;

    const maxStep = 50;

    // Should we scroll up?
    if (isInTopEdge && canScrollUp) {
      const intensity = (edgeTop - mousePointerY) / edgeSize;
      nextScrollY = nextScrollY - maxStep * intensity;

      // Should we scroll down?
    } else if (isInBottomEdge && canScrollDown) {
      const intensity = (mousePointerY - edgeBottom) / edgeSize;
      nextScrollY = nextScrollY + maxStep * intensity;
    }

    nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY));
    if (nextScrollY !== currentScrollY) {
      window.scrollTo(nextScrollX, nextScrollY);
      return true;
    } else {
      return false;
    }
  }
};
