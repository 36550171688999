import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Checkbox,
} from "@mui/material";
import Header from "../header/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deepClone,
  findDuplicateChassis,
  getNewData,
  checkDuplicatePriority,
  validateRange,
} from "./utils.js";
import "./table1.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const { REACT_APP_API_BASE_URL } = process.env;

export default function Table1({
  selectedLineValue,
  productionTargetVal,
  getChassisPreparationData,
  chassisData,
}) {
  const authInfo = useSelector((state) => state.userAuthInfoState);
  const [responseContent, setResponseContent] = React.useState([]);
  const [editedNotes, setEditedNotes] = React.useState();
  const [isInputField, setIsInputField] = React.useState(false);
  const [rowIndex, setRowIndex] = React.useState(); // This is for stations
  const [stationName, setStationName] = React.useState();
  const [outerRowIndex, setOuterRowIndex] = React.useState();
  const [innerRowIndex, setInnerRowIndex] = React.useState();
  const [chassisNo, setChassisNo] = React.useState("");
  const [editedChassisNo, setEditedChassisNo] = React.useState("");
  const [layoutData, setLayoutData] = React.useState([]);
  const [layoutDataCopy, setLayoutDataCopy] = React.useState([]);
  const [requestBody, setRequestBody] = React.useState();
  const [isVisible, setIsVisible] = React.useState(false);
  const [isPriorityCol, setIsPriorityCol] = React.useState(false);
  const [priorityVal, setPriorityVal] = React.useState("");
  const [completedItems, setCompletedItems] = React.useState("");
  const [toggleExpand, setToggleExpand] = React.useState(false);
  const [expandedRow, setExpandedRow] = React.useState();
  const [isChecked, setIsChecked] = React.useState(false);

  const location = useLocation();

  let searchQuery = { query: "", isRefresh: false };

  if (location.state) searchQuery = location.state;

  const fetchData = React.useCallback(async () => {
    fetch(`${REACT_APP_API_BASE_URL}/main-line-status/${selectedLineValue}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        // console.log("resData", selectedLineValue, responseData);
        if (
          Array.isArray(responseData.response_content) &&
          responseData.response_content.length > 0
        ) {
          // Parse each string in response_content as JSON
          const parsedContent = responseData.response_content.map((str) => {
            try {
              return JSON.parse(str.replace(/'/g, '"'));
            } catch (error) {
              console.error("Error parsing JSON string:", error);
              return null;
            }
          });

          // Remove null values from the parsed content
          const filteredContent = parsedContent.filter((item) => item !== null);

          const groupedData = groupRowsByStation(filteredContent);
          setResponseContent(groupedData);
          //fetchTableLayout(groupedData);
          // console.log("groupedData", groupedData);
        } else {
          // Handle the case where response_content is empty or not an array
          console.error("Invalid response format or empty data:", responseData);
          setResponseContent([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedLineValue]);
  // Function to group rows by station_name
  const groupRowsByStation = (data) => {
    const groupedData = {};
    data.forEach((row) => {
      const stationName = row.station_name;
      if (!groupedData[stationName]) {
        groupedData[stationName] = [];
      }
      groupedData[stationName].push(row);
    });
    return groupedData;
  };

  const fetchTableLayout = React.useCallback(async () => {
    fetch(`${REACT_APP_API_BASE_URL}/simulator_json_data/${selectedLineValue}`)
      .then((response) => response.json())
      .then((data) => {
        setLayoutDataCopy(deepClone(data));
        // const duplicateChassis = findDuplicateChassis(
        //   [],
        //   data[0],
        //   [],
        //   [],
        //   chassisData
        // );
        // getNewData(data[0], duplicateChassis);
        setLayoutData(data);
      })
      .catch((error) => {
        console.log("Error fetching Excel Data", error);
      });
  }, [selectedLineValue]);

  const fetchCompletedItems = React.useCallback(async () => {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/get_completed_items/${selectedLineValue}`
    );
    const resData = await response.json();
    const formattedData = resData.replace(/^,/, "").replace(/,/g, " ");
    setCompletedItems(formattedData);
  }, [selectedLineValue]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData, searchQuery]);

  React.useEffect(() => {
    fetchTableLayout();
  }, [fetchTableLayout, searchQuery]);

  React.useEffect(() => {
    fetchCompletedItems();
    const intervalId = setInterval(async () => {
      fetchData();
      fetchTableLayout();
      fetchCompletedItems();
      getChassisPreparationData();
    }, 12000);
    return () => clearInterval(intervalId);
  }, [fetchTableLayout]);

  const handleNotesInputField = (stationName, index, notes, chassis) => {
    setEditedNotes(notes);
    setIsInputField(true);
    setRowIndex(index);
    setChassisNo(chassis);
    setStationName(stationName);
  };
  const handleNotes = (event) => {
    setEditedNotes(event.target.value);
    responseContent[stationName][rowIndex].notes = event.target.value;

    setRequestBody({
      // createdDate: Date.now(),
      chassisNo: chassisNo.toString() ?? "",
      notes: event.target.value ?? "",
    });
  };
  const handlePostNotes = (event) => {
    if (event.key === "Enter") {
      const apiUrl = `${REACT_APP_API_BASE_URL}/update_notes_by_chassis_number/${chassisNo.toString()}`;
      setIsInputField(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      const delayPost = setTimeout(() => {
        fetch(apiUrl, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            // console.log(response);
          })
          .catch((error) => {
            console.log("Update error", error);
          });
      }, 1500);
      return () => clearTimeout(delayPost);
    }
    if (event.key === "Escape") {
      setIsInputField(false);
    }
  };

  //----------------------------------------------------------------------
  const handleChassisInput = (mainRowIndex, subRowIndex, rowName) => {
    setOuterRowIndex(mainRowIndex);
    setInnerRowIndex(subRowIndex);
    setIsInputField(true);
    setIsPriorityCol(false);
  };
  const handleChassisEdit = (rowName, event, subRowIndex) => {
    setEditedChassisNo(event.target.value);
    layoutData[0][rowName][subRowIndex].chassisNo = event.target.value;
    // console.log("copy", layoutDataCopy[0][rowName][subRowIndex].chassisNo);
  };

  const handleGetChassis = async (rowName, event, subRowIndex, chassisNo) => {
    setIsVisible(false);
    if (event.key === "Enter") {
      setIsVisible(true);
      setIsInputField(false);

      if (chassisNo !== "") {
        try {
          const dupRes = await fetch(
            `${REACT_APP_API_BASE_URL}/check_duplicate_chassis/${selectedLineValue}/${chassisNo}`
          );
          if (dupRes.ok) {
            const dupResData = await dupRes.json();
            if (dupResData) {
              toast.error("You have entered a duplicate value!");
              return;
            }
          }
        } catch (error) {
          console.log("Error checking duplicate chassis", error);
          return;
        }
      }

      const requestBody = {
        index: subRowIndex,
        priorityNo:
          layoutData[0][rowName][subRowIndex].priorityNo?.toString() ?? "",
        chassisNo: chassisNo ?? "",
        model: "",
        dealer: "",
        notes: "",
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };
      fetch(
        `${REACT_APP_API_BASE_URL}/update_simulator_json_data/${selectedLineValue}/${rowName}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data[0][rowName][subRowIndex]?.chassisNo === "") {
            toast.error("No data found..!");
          }
          setLayoutData(data);
        })
        .catch((error) => {
          console.log("Error fetching Data", error);
        });
    }
  };
  const handleOnBlur = (rowName, event, subRowIndex) => {
    if (!isVisible) {
      layoutData[0][rowName][subRowIndex].chassisNo =
        layoutDataCopy[0][rowName][subRowIndex].chassisNo;
    }
  };
  const handleGetChassisNo = (stationName, indexVal, row) => {
    // if (row.chassisNo !== "") {
    //   const requestBody = {
    //     stationName: stationName,
    //     chassisNo: row.chassisNo ?? "",
    //     model: row.model,
    //     dealer: row.dealer,
    //     notes: row.notes,
    //     is_completed: "False",
    //     is_onhold: "False",
    //   };
    //   // console.log("req-body - ", requestBody);
    //   const requestOptions = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(requestBody),
    //   };
    //   fetch(
    //     `${REACT_APP_API_BASE_URL}/update_chassis_on_hold_two`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       // console.log("-----------------", data.response_data);
    //       if (
    //         Array.isArray(data.response_data) &&
    //         data.response_data.length > 0
    //       ) {
    //         // const newIntervalValue = responseData.interval;
    //         // setIntervalValue(newIntervalValue + 100);
    //         // Parse each string in response_content as JSON
    //         const parsedContent = data.response_data.map((str) => {
    //           try {
    //             return JSON.parse(str.replace(/'/g, '"'));
    //           } catch (error) {
    //             console.error("Error parsing JSON string:", error);
    //             return null;
    //           }
    //         });
    //         // Remove null values from the parsed content
    //         const filteredContent = parsedContent.filter(
    //           (item) => item !== null
    //         );
    //         const groupedData = groupRowsByStation(filteredContent);
    //         setResponseContent(groupedData);
    //         fetchTableLayout(groupedData);
    //       } else {
    //         // Handle the case where response_content is empty or not an array
    //         console.error("Invalid response format or empty data:", data);
    //         setResponseContent([]);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("Error fetching Data", error);
    //     });
    // }
  };

  //-----------------------------------------------------------
  const handlePriorityInput = (mainRowIndex, subRowIndex, rowName) => {
    setOuterRowIndex(mainRowIndex);
    setInnerRowIndex(subRowIndex);
    setIsInputField(true);
    setIsPriorityCol(true);
    setPriorityVal("");
  };
  const handleChangePriority = (rowName, event, subRowIndex) => {
    setPriorityVal(event.target.value);
    layoutData[0][rowName][subRowIndex].priorityNo = event.target.value;
  };
  const handleSetPriority = (rowName, event, subRowIndex, row) => {
    if (event.key === "Enter") {
      setIsInputField(false);
      setIsPriorityCol(false);
      const isPriorityValidated = validateRange(
        priorityVal,
        productionTargetVal
      );
      layoutData[0][rowName][subRowIndex].priorityNo = priorityVal;
      if (priorityVal > productionTargetVal || !isPriorityValidated) {
        layoutData[0][rowName][subRowIndex].priorityNo = "";
        setPriorityVal("");
        toast.error("Invalid Input");
      } else {
        const isDuplicatePriority = checkDuplicatePriority(
          priorityVal,
          layoutDataCopy[0]["Chassis in Stock"]
        );
        if (isDuplicatePriority && rowName === "Chassis in Stock") {
          toast.error("Can Not Assign Duplicate Priority");
          layoutData[0]["Chassis in Stock"][subRowIndex].priorityNo = "";
        } else {
          layoutDataCopy[0]["Chassis in Stock"][subRowIndex].priorityNo =
            priorityVal;
        }
      }

      const requestBody = {
        index: subRowIndex,
        chassisNo: row.chassisNo ?? "",
        priorityNo: row.priorityNo ?? "",
        model: row.model ?? "",
        dealer: row.dealer ?? "",
        notes: "",
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };
      fetch(
        `${REACT_APP_API_BASE_URL}/update_simulator_json_data/${selectedLineValue}/${rowName}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
        });
    }
  };
  const handleExpandRow = (row, subRowIndex, rowName, mainRowIndex) => {
    setToggleExpand((prevState) => !prevState);
    setExpandedRow(mainRowIndex);
  };

  const handleCheckBoxChecked = (event, row) => {
    let requestBody = {
      chassisNo: row.chassisNo,
      station_name: row.station_name,
    };

    if (event.target.checked) {
      requestBody.is_completed = "True";
    } else {
      requestBody.is_completed = "False";
    }
    axios
      .post(
        `${REACT_APP_API_BASE_URL}/update_processed_data/${selectedLineValue}`,
        requestBody
      )
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          // Parse each string in response_content as JSON
          const parsedContent = response.data.map((str) => {
            try {
              return JSON.parse(str.replace(/'/g, '"'));
            } catch (error) {
              console.error("Error parsing JSON string:", error);
              return null;
            }
          });

          // Remove null values from the parsed content
          const filteredContent = parsedContent.filter((item) => item !== null);

          const groupedData = groupRowsByStation(filteredContent);
          setResponseContent(groupedData);
        } else {
          // Handle the case where response_content is empty or not an array
          console.error("Invalid response format or empty data:");
          setResponseContent([]);
        }
      })
      .catch((error) => console.log("Error", error));
  };

  return (
    <Container
      className="p-0 ms-0"
      style={{ textAlign: "center", maxWidth: "1150px" }}
    >
      {/* <Header /> */}
      {Object.keys(responseContent).length > 0 ? (
        <TableContainer component={Paper} style={{ marginTop: "8px" }}>
          <Table size="small" className="p-0">
            <TableHead>
              <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
                <TableCell style={{ width: "200px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    Station Name
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "110px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    Priority No
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "110px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    Chassis No
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "190px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    Model
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "180px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    Dealer
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "220px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    Notes
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    On Hold
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(authInfo.roles?.includes("user.admin") ||
                authInfo.roles?.includes("inventory_staff") ||
                authInfo.roles?.includes("analyst_staff")) &&
                layoutData.length > 0 && (
                  <>
                    {Object.keys(layoutData[0]).map((rowName, mainRowIndex) => (
                      <React.Fragment key={mainRowIndex}>
                        {layoutData[0][rowName].map((row, subRowIndex) => (
                          <TableRow
                            key={subRowIndex}
                            style={{
                              backgroundColor: "#F9F9F9",
                            }}
                          >
                            {subRowIndex === 0 && (
                              <>
                                <TableCell
                                  onClick={() =>
                                    handleExpandRow(
                                      row,
                                      subRowIndex,
                                      rowName,
                                      mainRowIndex
                                    )
                                  }
                                  className="cursor-pointer"
                                  rowSpan={layoutData[0][rowName].length}
                                  style={{
                                    backgroundColor:
                                      rowName === "Chassis in Stock" &&
                                      layoutDataCopy[0][rowName].filter(
                                        (key, index) => {
                                          return key.chassisNo && key.chassisNo;
                                        }
                                      ).length < 7
                                        ? "#f39ba2"
                                        : "inherit",
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Typography
                                      variant="body1"
                                      style={{
                                        fontFamily:
                                          "League Spartan, sans-serif",
                                      }}
                                    >
                                      {rowName}
                                    </Typography>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="9"
                                      viewBox="0 0 16 9"
                                      fill="none"
                                    >
                                      <path
                                        d="M2 2L8 8L14 2"
                                        stroke="#25282B"
                                        strokeWidth="2"
                                        strokeLinecap="square"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </TableCell>
                                {Array(6)
                                  .fill()
                                  .map((_, i) => (
                                    <TableCell
                                      key={i}
                                      className={`expandable-row ${
                                        toggleExpand &&
                                        expandedRow === mainRowIndex
                                          ? "close"
                                          : ""
                                      }`}
                                    ></TableCell>
                                  ))}
                              </>
                            )}
                            <TableCell
                              className={`expandable-row ${
                                toggleExpand && expandedRow === mainRowIndex
                                  ? "expand"
                                  : "close"
                              }`}
                              style={{ height: "30px" }}
                              onClick={() =>
                                handlePriorityInput(
                                  mainRowIndex,
                                  subRowIndex,
                                  rowName
                                )
                              }
                            >
                              {(authInfo.roles?.includes("user.admin") ||
                                (authInfo.roles?.includes("inventory_staff") &&
                                  rowName === "Chassis in Stock")) &&
                              isInputField &&
                              outerRowIndex === mainRowIndex &&
                              innerRowIndex === subRowIndex &&
                              isPriorityCol ? (
                                <input
                                  style={{ width: "100%" }}
                                  type="text"
                                  onChange={(event) =>
                                    handleChangePriority(
                                      rowName,
                                      event,
                                      subRowIndex
                                    )
                                  }
                                  value={
                                    layoutData[0][rowName][subRowIndex]
                                      ?.priorityNo
                                  }
                                  onKeyDown={(event) =>
                                    handleSetPriority(
                                      rowName,
                                      event,
                                      subRowIndex,
                                      row
                                    )
                                  }
                                  pattern="^[1-9][0-9]*[ab]?$"
                                  onBlur={(event) =>
                                    handleOnBlur(rowName, event, subRowIndex)
                                  }
                                ></input>
                              ) : (
                                <Typography variant="body1">
                                  {row.priorityNo}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              className={`expandable-row ${
                                toggleExpand && expandedRow === mainRowIndex
                                  ? "expand"
                                  : "close"
                              }`}
                              style={{ height: "30px" }}
                              onClick={() =>
                                handleChassisInput(
                                  mainRowIndex,
                                  subRowIndex,
                                  rowName
                                )
                              }
                            >
                              {(authInfo.roles?.includes("user.admin") ||
                                (authInfo.roles?.includes("inventory_staff") &&
                                  rowName === "Chassis in Stock")) &&
                              isInputField &&
                              outerRowIndex === mainRowIndex &&
                              innerRowIndex === subRowIndex &&
                              !isPriorityCol ? (
                                <input
                                  style={{ width: "100%" }}
                                  type="text"
                                  onChange={(event) =>
                                    handleChassisEdit(
                                      rowName,
                                      event,
                                      subRowIndex
                                    )
                                  }
                                  value={
                                    layoutData[0][rowName][subRowIndex]
                                      ?.chassisNo
                                  }
                                  onKeyDown={(event) =>
                                    handleGetChassis(
                                      rowName,
                                      event,
                                      subRowIndex,
                                      layoutData[0][rowName][subRowIndex]
                                        ?.chassisNo
                                    )
                                  }
                                  onBlur={(event) =>
                                    handleOnBlur(rowName, event, subRowIndex)
                                  }
                                ></input>
                              ) : (
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontFamily: "League Spartan, sans-serif",
                                    color: "black",
                                  }}
                                >
                                  {row.chassisNo}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              className={`expandable-row ${
                                toggleExpand && expandedRow === mainRowIndex
                                  ? "expand"
                                  : "close"
                              }`}
                              style={{ height: "30px" }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "League Spartan, sans-serif",
                                }}
                              >
                                {row.model}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`expandable-row ${
                                toggleExpand && expandedRow === mainRowIndex
                                  ? "expand"
                                  : "close"
                              }`}
                              style={{ height: "30px" }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "League Spartan, sans-serif",
                                }}
                              >
                                {row.dealer}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`expandable-row ${
                                toggleExpand && expandedRow === mainRowIndex
                                  ? "expand"
                                  : "close"
                              }`}
                              style={{ height: "30px" }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "League Spartan, sans-serif",
                                }}
                              >
                                {row.notes}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`expandable-row ${
                                toggleExpand && expandedRow === mainRowIndex
                                  ? "expand"
                                  : "close"
                              }`}
                              style={{ height: "30px" }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "League Spartan, sans-serif",
                                }}
                              ></Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                )}
              {(authInfo.roles?.includes("user.admin") ||
                authInfo.roles?.includes("assembly_staff") ||
                authInfo.roles?.includes("analyst_staff")) &&
                Object.keys(responseContent).map((station, i) => (
                  <React.Fragment key={station}>
                    {responseContent[station].map((row, index) => (
                      <TableRow
                        key={index}
                        style={{
                          background: `${i % 2 === 0 ? "#eaeae8" : "#F9F9F9"}`,
                          borderTop: `${
                            i === 0 &&
                            index === responseContent[station].length - 1
                              ? "20px solid #ffff"
                              : "inherit"
                          }`,
                        }}
                      >
                        {index === 0 && (
                          <TableCell rowSpan={responseContent[station].length}>
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {station}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell colSpan={2}>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {/* Removable */}
                            {
                              <Checkbox
                                disabled={!row.chassisNo}
                                checked={row.is_completed === "True"}
                                onChange={(event) =>
                                  handleCheckBoxChecked(event, row)
                                }
                              />
                            }
                            {row.chassisNo}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "League Spartan, sans-serif" }}
                          >
                            {row.model}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "League Spartan, sans-serif" }}
                          >
                            {row.dealer}
                          </Typography>
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            handleNotesInputField(
                              station,
                              index,
                              row.notes,
                              row.chassisNo
                            )
                          }
                        >
                          {isInputField &&
                          index === rowIndex &&
                          station === stationName ? (
                            <input
                              type="text"
                              value={editedNotes}
                              onChange={handleNotes}
                              onKeyDown={handlePostNotes}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {row.notes}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <button
                            className="chassis-btn d-flex align-items-center justify-content-center flex-column"
                            onClick={() =>
                              handleGetChassisNo(station, index, row)
                            }
                          >
                            <span className="d-block">&#8593;</span>
                            <span className="chassis-btn--tooltip">
                              Add To Chassis on hold
                            </span>
                          </button>
                          {row?.is_onhold === "True" ? (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "League Spartan, sans-serif",
                              }}
                            >
                              {row?.chassisNo}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          {(authInfo.roles?.includes("user.admin") ||
            authInfo.roles?.includes("analyst_staff")) && (
            <>
              <Table style={{ marginTop: "20px" }}>
                <TableBody>
                  <TableRow className="mt-5">
                    <TableCell style={{ width: "203px" }}>
                      <Typography>JOBS FOR THE WEEK</Typography>
                    </TableCell>
                    <TableCell>{completedItems}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        </TableContainer>
      ) : (
        <Typography
          variant="body1"
          style={{ marginTop: "8px", fontFamily: "League Spartan, sans-serif" }}
        >
          Loading...
        </Typography>
      )}
    </Container>
  );
}
