import { CellLocation, Id, ReactGrid } from "@silevis/reactgrid";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import "./style.css";
import ScheduleModal from "./ScheduleFeedModal/ScheduleModal";
import {
  ScheduledFeedPresenter,
  debounce,
  handleDragRow,
} from "./ScheduledFeed-presenter";
import { ResponseField } from "./ScheduledFeed-models";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { FeaturesPresenter } from "../featuresPresenter/featuresPresenter";

function ScheduleFeed() {
  const scheduledDataPresenter = new ScheduledFeedPresenter();
  const featuresDataPresenter = new FeaturesPresenter();
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const [data, setData] = useState<ResponseField[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalSaveLoading, setModalSaveLoading] = useState(false);
  const columns = scheduledDataPresenter.tableColumns;
  const [rows, setRows] = useState<any>();
  const [tabToggle, setTabToggle] = useState(1);
  const lineOptionsValue = featuresDataPresenter.getLineOptions();
  const [selectedOption, setSelectedOption] = useState("main-line");
  const [tableModalShow, setTableModalShow] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<CellLocation>();
  const [selectedCheckBoxIds, setSelectedCheckBoxIds] = useState<string[]>([]);
  const [rowLockState, setRowLockState] = useState<boolean>(false);
  const [isReorderTriggered, setIsReOrderTriggered] = useState<boolean>(false);

  const location = useLocation();

  let searchQuery = { query: "", isRefresh: false };
  if (location.state) searchQuery = location.state;

  async function loadLineData() {
    setLoading(true);
    try {
      const rowsWithFormattedIds = await scheduledDataPresenter.loadLineData(
        selectedOption,
        searchQuery.query,
        searchQuery.isRefresh
      );
      setData(rowsWithFormattedIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }
  const debouncedMainLineDataLoad = debounce(() => loadLineData(), 1000);

  useEffect(() => {
    if (searchQuery.isRefresh) loadLineData();
    else {
      debouncedMainLineDataLoad();
    }
  }, [searchQuery.isRefresh, searchQuery.query, selectedOption]);

  useEffect(() => {
    setRows(scheduledDataPresenter.getRows(data));
  }, [data, selectedCheckBoxIds]);

  useEffect(() => {
    if (isReorderTriggered) {
      window.addEventListener("mousemove", handleDragRow, {
        capture: false,
        once: false,
        passive: true,
      });
    }
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleDragRow);
    };
  }, [isReorderTriggered]);

  const handleOpenTableModal = (location: CellLocation) => {
    setTableModalShow(true);
    setSelectedRow(location);
    const selectedIdsString =
      data[Number(location.rowId)]?.ScheduleSubStatus?.split(",") ?? [];
    setSelectedCheckBoxIds(selectedIdsString);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idNumber = event.target.id.split("-")[1];
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckBoxIds((prevIds: any) => [...prevIds, idNumber]);
    } else {
      setSelectedCheckBoxIds((prevIds: any) =>
        prevIds.filter((id: any) => id !== idNumber)
      );
    }
    return "";
  };

  const handleChangeLockState = (lockVal: boolean) => {
    setRowLockState(lockVal);
  };

  const handleSaveTableModal = async (
    rowIndex: any,
    checkBoxIds: string[],
    textValues: any,
    selectedLine: string,
    reservationVal: string,
    hideModal: any
  ) => {
    const rowData = data[Number(rowIndex)];
    const fromChassisNo = data[Number(rowIndex)].Serial;
    setModalSaveLoading(true);
    try {
      if (Object.values(textValues).some((value) => Boolean(value))) {
        const swapOptionsResponse =
          await scheduledDataPresenter.updateModalSwapOptions(
            textValues,
            rowIndex,
            selectedLine,
            fromChassisNo
          );
        if (swapOptionsResponse) setData(swapOptionsResponse);
      }
      const response = await scheduledDataPresenter.handleModalDataChange(
        rowData,
        checkBoxIds,
        selectedOption,
        reservationVal,
        rowLockState
      );
      setData(response);
      setRows(scheduledDataPresenter.getRows(data));
      data[Number(rowIndex)].reservationVal = reservationVal;
    } catch (error) {
      console.log("Update error", error);
    }
    setModalSaveLoading(false);
    setTableModalShow(false);
    hideModal();
  };

  const handleRowsReorder = async (targetRowId: Id, movingRowIds: Id[]) => {
    const target = Number(targetRowId);
    const reorderedRows = scheduledDataPresenter.getReorderedRows(target, movingRowIds, data); 
    setIsReOrderTriggered(false);
    const isRowLocked = movingRowIds.some(
      (rowId) => data[Number(rowId)].isLocked
    );
    if (isRowLocked) {
      toast.error("Selected row is locked");
    } else {
      setLoading(true);
      try {
        const reorderedData =
          await scheduledDataPresenter.reorderDataRowAndSave(
            data,
            targetRowId,
            movingRowIds,
            selectedOption
          );
        const dbResponse = await scheduledDataPresenter.sendReorderedDataToDb(reorderedRows.oldRows, reorderedRows.newRows)
        console.log('dbResponse', dbResponse);
        setData(reorderedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("reordering error", error);
      }
    }
  };

  const handleCanReorderRows = (targetRowId: Id): boolean => {
    console.log('target', targetRowId)
    setIsReOrderTriggered(true);
    return targetRowId !== "header";
  };

  const handleRearrange = async () => {
    const responseData = await scheduledDataPresenter.handleRearrange(
      selectedOption
    );
    setData(responseData);
  };

  const handleDeleteReservation = async (onHideModal: any) => {
    setModalSaveLoading(true);
    try {
      const response = await scheduledDataPresenter.handleRemoveReserveItem(selectedOption, Number(selectedRow?.rowId))
      setData(response);
      toast.success('Reserved Item Deleted Successfully')
    } catch (error) {
      toast.error('Could Not Delete Reserved Item')
      console.log('Failed', error)
    }
    setModalSaveLoading(false);
    onHideModal();
  }

  return (
    <div>
      <div
        className="myGrid"
        style={{
          width: `${!loading && data.length ? "max-content" : "100vw"}`,
        }}
      >
        <div className="position-sticky bg-white z-3" style={{ top: "64px" }}>
          <div className="d-flex gap-4 align-items-center py-2">
            <h1 className="mb-2 fw-semibold">Crusader Production Schedule</h1>
            <select
              className="form-select"
              aria-label="Table Select"
              onChange={(event) => setSelectedOption(event.target.value)}
            >
              {lineOptionsValue.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.displayText}
                </option>
              ))}
            </select>
            <button className="btn btn-danger" onClick={handleRearrange}>
              Re-arrange
            </button>
          </div>
        </div>
        <div className="tab d-flex gap-2 mb-3">
          <button
            className={`px-3 py-1 ${tabToggle === 1 ? "active" : ""}`}
            onClick={() => setTabToggle(1)}
          >
            Department 1
          </button>
          <button
            className={`px-3 py-1 ${tabToggle === 2 ? "active" : ""}`}
            onClick={() => setTabToggle(2)}
          >
            Department 2
          </button>
        </div>
        {loading && (
          <div className="loader-wrapper">
            <div className="loader-spinner">
              <BeatLoader
                loading={loading}
                color="#432a70"
                cssOverride={{
                  borderColor: "#432a70",
                  opacity: 1,
                }}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        )}
        {isBigScreen ? (
            <>
              <div
                className={`schedule-feed__data-grid ${
                  tabToggle === 1 ? "d-block" : "d-none"
                }`}
              >
                {!data.length && !loading ? (
                  <p>Data not available!</p>
                ) : (
                  <div className="position-sticky">
                    <ReactGrid
                      stickyTopRows={1}
                      rows={rows ?? []}
                      columns={columns ?? []}
                      customCellTemplates={{anchor: scheduledDataPresenter.AnchorCellTemplate}}
                      onFocusLocationChanged={handleOpenTableModal}
                      onRowsReordered={handleRowsReorder}
                      canReorderRows={handleCanReorderRows}
                      enableRowSelection
                    />
                  </div>
                )}
              </div>
              <div className={tabToggle === 2 ? "d-block" : "d-none"}>
                <p>Tab 2</p>
              </div>
              {tableModalShow &&
                selectedRow &&
                selectedRow.columnId === "Edit" && (
                  <ScheduleModal
                    show={tableModalShow}
                    onHide={() => setTableModalShow(false)}
                    handleSaveModal={handleSaveTableModal}
                    checkBoxIds={selectedCheckBoxIds}
                    handleCheckBoxChange={handleCheckboxChange}
                    rowData={rows}
                    tableData={data}
                    isSaving={modalSaveLoading}
                    rowindex={selectedRow.rowId}
                    selectedLine={selectedOption}
                    handleChangeLockState={handleChangeLockState}
                    handleDeleteReservation={handleDeleteReservation}
                  ></ScheduleModal>
                )}
            </>
          ) : (
            <div></div>
          )}
      </div>
    </div>
  );
}

export default ScheduleFeed;
