import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import userStorage from "./userStorage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IUserInfo } from "../store/actions";
import "./Layout.css";
import userPresenter from "../authentications/userPresenter";
import Login from "../authentications/Login";

const Layout = () => {
  const currentUserInfo: IUserInfo = useSelector((state: any) => {
    return state.userAuthInfoState;
  });

  const [refVal, setRefVal] = useState<any>({ query: "", isRefresh: false });
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    userPresenter.logOut();
    navigate("/home");
  };

  useEffect(() => {
    setRefVal({ query: "", isRefresh: false });
  }, [location.pathname]);

  useEffect(() => {
    navigate(`${location.pathname}`, { state: refVal });
  }, [refVal]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefVal({ query: event.target.value, isRefresh: false });
  };

  const handleRefresh = () => {
    setRefVal((prevState: any) => ({
      ...prevState,
      query: "",
      isRefresh: !prevState.isRefresh,
    }));
  };

  return (
    <main className="App">
      {currentUserInfo?.roles ? (
        <nav className="navbar nav fixed-top navbar-expand-xl">
          <div className="container-fluid">
            <div className="d-flex flex-row-reverse flex-lg-row align-items-center gap-2">
              <h1 className="m-0 d-flex justify-content-center align-items-center">
                <div className="logo navbar-brand p-0 m-0 d-flex justify-content-center align-items-center">
                  <img src="./Crusader_Shield.png" alt="Logo" />
                </div>
              </h1>
              <div className="nav__search-box position-relative">
                <input
                  className="rounded-sm form-control"
                  placeholder="Free Text Search"
                  type="text"
                  value={refVal.query}
                  onChange={handleSearch}
                />
                <span className="nav__search-box__icon d-flex align-items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="MagnifyingGlass">
                      <path
                        id="Vector"
                        d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
                        stroke="#1D2026"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M16.4431 16.4438L20.9994 21.0002"
                        stroke="#1D2026"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </span>
              </div>
              <div className="refresh-button">
                <button
                  className="btn white-btn-outlined fw-medium"
                  onClick={handleRefresh}
                >
                  Refresh
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center gap-4">
              <button
                className="navbar-toggler border-0 text-black p-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            {currentUserInfo.accessToken && currentUserInfo.roles && (
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto align-items-start align-items-xl-center mt-2 mt-xl-0">
                  {currentUserInfo.roles.includes("user.admin") && (
                    <>
                      <li className="nav-item">
                        <NavLink to="/schedule" className="nav-link">
                          Schedule
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/daily-review" className="nav-link">
                          Production Review
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <NavLink to="/pick-list" className="nav-link">
                          Pick List
                        </NavLink>
                      </li> */}
                      <li className="nav-item">
                        <NavLink to="/form" className="nav-link">
                          Config
                        </NavLink>
                      </li>
                    </>
                  )}
                  <li>
                    <span className="d-flex justify-content-center align-items-center gap-3">
                      <span className="user-info position-relative d-flex justify-content-center align-items-center">
                        <span className="m-0 text-white user-info__tooltip font-md">
                          {currentUserInfo.name}
                        </span>
                        <img
                          className="button__image"
                          src="./avatar.jpeg"
                          alt=""
                        />
                      </span>
                      <span className="m-0 text-white font-lg">
                        {currentUserInfo?.name}
                      </span>
                    </span>
                  </li>
                  <li className="nav-item">
                    <button
                      className="font-lg d-flex justify-content-center align-items-center bg-transparent border-0"
                      onClick={logout}
                    >
                      <p className="m-0 font-lg text-white">Logout</p>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      ) : (
        <></>
      )}
      <div
        className="container-fluid main-container position-relative"
        style={{ marginTop: "60px" }}
      >
        {currentUserInfo?.roles ? <Outlet /> : <Login />}
      </div>
    </main>
  );
};

export default Layout;
