import "./App.css";
import { ToastContainer } from "react-toastify";
import ScheduleGrid from "./app/features/scheduleGrid/scheduleGrid";
import DailyReview from "./app/features/dailyReview/dailyReview";
import { Route, Routes, redirect } from "react-router-dom";
import Layout from "./app/common/Layout";
import PageNotFound from "./app/common/PageNotFound";
import Login from "./app/authentications/Login";
import RequireAuth from "./app/authentications/RequireAuth";
import DownloadFiles from "./app/features/dailyReview/components/downlodFiles/DownloadFiles";
import ResponsiveForm from "./app/features/scheduleFeed/ResponsiveForm";
import ProductionForm from "./app/features/dailyReview/components/settingsTextForm/settingsForm";
import ScheduleFeed from "./app/features/scheduleFeed/scheduleFeed";
import { useSelector } from "react-redux";
import { IUserInfo } from "./app/store/actions";
import PickList from "./app/features/pickList/PickList";

function App() {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  return (
    <>
      <Routes>
        <Route path="/home" element={<Login></Login>} />
        <Route path="/" element={<Layout></Layout>}>
          {authInfo?.roles?.includes("user.admin") ? (
            <>
              <Route element={<RequireAuth />}>
                <Route
                  path="/"
                  element={<ScheduleFeed></ScheduleFeed>}
                />
                <Route
                  path="/daily-review"
                  element={<DailyReview></DailyReview>}
                />
                <Route path="/download" element={<DownloadFiles />} />
                <Route
                  path="/ScheduleGrid"
                  element={<ScheduleGrid></ScheduleGrid>}
                />
                {/* <Route
                  path="/schedule"
                  element={<ScheduleFeed></ScheduleFeed>}
                /> */}
                <Route path="/configuration" element={<ProductionForm />} />
                <Route
                  path="/form"
                  element={<ResponsiveForm></ResponsiveForm>}
                />
                <Route path="/schedule"  element={<ScheduleFeed></ScheduleFeed>} />
                {/* <Route path="pick-list" element={<PickList></PickList>} /> */}
              </Route>
            </>
          ) : (
            <>
              <Route element={<RequireAuth />}>
                {/* <Route path="/home" element={<DailyReview></DailyReview>} /> */}
                <Route
                  path="/"
                  element={<DailyReview></DailyReview>}
                />
                <Route
                  path="/daily-review"
                  element={<DailyReview></DailyReview>}
                />
              </Route>
            </>
          )}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
