import axios from "axios";
import jwt from "jwt-decode";
import userStorage from "../common/userStorage";
import store from "../store/store";
import { AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";
import { IUserInfo, getUserInfoPayload } from "../store/actions";
import { REACT_APP_CLIENT_ID, REACT_APP_APP_BASE_URL, REACT_APP_TENANT_ID, REACT_APP_API_BASE_URL } from "../../generated/proxies";

class UserPresenter {
    private msalConfig = {
        auth: {
            clientId: REACT_APP_CLIENT_ID || "",
            redirectUri: REACT_APP_APP_BASE_URL,
            authority: `https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`,
            scopes: ["user.read", "offline_access", "openid", "profile"],
        },
    };
    private pca: PublicClientApplication | null = null;

    async login() {
        if (!this.pca) this.pca = new PublicClientApplication(this.msalConfig);
        await this.pca.initialize();
        const res = await this.pca.loginPopup({
            prompt: "select_account",
            scopes: ["user.read", "offline_access", "openid", "profile"],
        });
        const result = await this.store(res)

        return result
            ? result.roles?.includes("user.admin")
                ? '/schedule'
                : '/daily-review'
            : '/home';
    }

    async store(res: AuthenticationResult) {
        const jwtResponse: any = jwt(res?.accessToken);
        // console.log(res);
        const tokenRes: any = await axios.post(
            `${REACT_APP_API_BASE_URL}/token_microsoft`,
            null,
            { headers: { Authorization: `Bearer ${res.idToken}` } }
        );

        const userInfo: IUserInfo = {
            accessToken: tokenRes?.data?.access_token,
            refreshToken: tokenRes?.data?.refresh_token,
            name: jwtResponse?.name,
            email: jwtResponse?.unique_name,
            roles: tokenRes?.data?.claims?.roles,
        };
        if (userInfo.roles === undefined) {
            this.remove();
            return null;
        }
        else {
            // update localstorage
            userStorage.saveUserAuthInfo(userInfo);
            // update REDUX STORAGE
            store.dispatch(getUserInfoPayload(userInfo));
            return userInfo;
        }
    }

    async update() {
        const authInfo: IUserInfo = userStorage.getUserAuthInfo();
        let formData = new FormData();
        formData.append("refresh_token", authInfo?.refreshToken);
        const response = await axios({
            method: "post",
            url: `${REACT_APP_API_BASE_URL}/refresh_token`,
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        const userInfo: IUserInfo = {
            accessToken: response?.data?.access_token,
            refreshToken: response?.data?.refresh_token,
            name: authInfo?.name,
            email: authInfo?.email,
            roles: authInfo?.roles,
        }
        userStorage.updateUserAuthToken(userInfo);
        store.dispatch(getUserInfoPayload(userInfo));
        return userInfo;
    }

    logOut() {
        this.remove();
    }

    remove() {
        userStorage.clearData();
        store.dispatch(getUserInfoPayload(null));
    }

}

const userPresenter = new UserPresenter();
export default userPresenter;