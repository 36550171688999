import React, { useState } from "react";
import "./styles.css";

import Header from "./components/header/Header";
import Table1 from "./components/table1/table1";
import DownloadFiles from "./components/downlodFiles/DownloadFiles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTopOnPageChange from "./components/scrollToTopOnPageChange/ScrollToTopOnPageChange";
import Preparation from "./components/table2/Preparation";
import ChassisTable from "./components/ChassisTable/ChassisTable";
import Timer from "./Timer";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import CompletedItems from "./components/table1/CompletedItems";

const { REACT_APP_API_BASE_URL } = process.env;

export default function DailyReview() {
  const [selectedLineValue, setSelectedLineValue] = useState("main-line");
  const [tab, setTab] = useState(1);
  const [resetLoader, setResetLoader] = useState(false);
  const [resetLoaderTwo, setResetLoaderTwo] = useState(false);
  const [prepLoader, setPrepLoader] = useState(false);
  const [prodTargetVal, setProdTargetVal] = useState("");
  const [chassisData, setChassisData] = useState([]);
  const [crvChassisData, setCrvChassisData] = useState([]);
  const authInfo = useSelector((state) => {
    return state.userAuthInfoState;
  });

  const fetchProdTargetVal = async () => {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/get_production_target_val/${selectedLineValue}`
    );
    const resData = await response.json();
    setProdTargetVal(parseInt(resData, 10));
  };
  const getChassisPreparationData = async () => {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/get_chassis_preparation_data`
    );
    const responseData = await response.json();
    setChassisData(responseData);

    const crvResponse = await fetch(
      `${REACT_APP_API_BASE_URL}/get_crv_chassis_preparation_data`
    );
    const crvChassisData = await crvResponse.json();
    setCrvChassisData(crvChassisData);
  };
  React.useEffect(() => {
    fetchProdTargetVal();
  }, [selectedLineValue]);
  React.useEffect(() => {
    getChassisPreparationData();
  }, []);
  const handleLineValue = (e) => {
    // console.log(e.target.value)
    setSelectedLineValue(e.target.value);
  };
  const updateTabToggle = (id) => {
    setTab(id);
    if (id === 1) {
      setSelectedLineValue("main-line");
    }
  };
  const preparePreparationData = async () => {
    setPrepLoader(true);
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/chassis_preparation_data${selectedLineValue}`
    );
    const resData = await response.json();
    selectedLineValue === "main-line"
      ? setChassisData(resData)
      : setCrvChassisData(resData);
    setPrepLoader(false);
  };

  const resetChassisPreparationData = async () => {
    setResetLoaderTwo(true);
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/reset_line_preparation_data/${selectedLineValue}`
    );
    const resData = await response.json();
    setChassisData(resData);
    setResetLoaderTwo(false);
  };

  const resetResponseData = async () => {
    setResetLoader(true);
    // setUpdateButton(!updateButton)
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/reset_response_data/${selectedLineValue}`
    );
    const resData = await response.json();
    getChassisPreparationData();
    // setResponseConData(resData)
    setResetLoader(false);
  };
  const handlePostChassisFromChassisTable = async (
    event,
    item,
    lineVal,
    chassisVal
  ) => {
    if (event.key === "Enter") {
      // console.log(chassisVal)
      // setIsInputField(false);
      const res = await fetch(
        `${REACT_APP_API_BASE_URL}/check_duplicate_chassis/${lineVal}/${chassisVal}`
      );
      const responseData = await res.json();

      if (!responseData) {
        const apiUrl = `${REACT_APP_API_BASE_URL}/update_line_preparation/${lineVal}`;
        const requestBody = {
          index: item.index,
          chassisNo: chassisVal,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        };
        fetch(apiUrl, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setChassisData(data);
            getChassisPreparationData();
            if (!data.success) {
              toast.error("Data not found");
            }
          });
      } else {
        toast.error("You have entered duplicate value!");
        getChassisPreparationData();
      }
    }
  };

  const hasPrepRole = authInfo.roles?.some((role) => role.includes("prep"));
  if (hasPrepRole) {
    return (
      <div className="position-relative">
        <Timer />
        <div className="mx-auto" style={{ maxWidth: "1500px" }}>
          <h1 className="fw-semibold mb-3 mt-2">Crusader Production Review</h1>
          <div className="d-flex gap-3">
            <Preparation />
            <div className="flex-column gap-5 flex-grow-1">
              <h5 className="mt-2">Main Line Preparation</h5>
              <ChassisTable
                chassisData={chassisData}
                productionTargetVal={prodTargetVal}
              />
              <h5 className="mt-2">CRV Line Preparation</h5>
              <ChassisTable
                chassisData={crvChassisData}
                productionTargetVal={prodTargetVal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="position-relative">
        {authInfo.roles?.includes("user.admin") && (
          <nav className="d-flex justify-content-center align-items-center my-4">
            <div className="tab-container d-flex">
              <div className="selector"></div>
              <button
                className={`m-0 d-flex align-items-center gap-2 flex-row-reverse ${
                  tab === 1 ? "active" : ""
                }`}
                onClick={() => updateTabToggle(1)}
              >
                Assembly
              </button>
              <button
                className={`m-0 ${tab === 2 ? "active" : ""}`}
                onClick={() => updateTabToggle(2)}
              >
                Preparation
              </button>
            </div>
          </nav>
        )}
        <Timer></Timer>
        <div
          className="d-flex justify-content-between align-items-center mx-auto h-full mt-5"
          style={{ maxWidth: "1500px" }}
        >
          <div className="d-flex align-items-center gap-4">
            <h1 className="fw-semibold mb-0">Crusader Production Review</h1>
            {tab === 1 ? (
              <div className="d-flex">
                <select
                  className="form-select"
                  aria-label="Table Select"
                  onChange={handleLineValue}
                >
                  <option className="text-black" value="main-line">
                    Main Line
                  </option>
                  <option className="text-black" value="crv-line">
                    CRV Line
                  </option>
                </select>
                {authInfo.roles?.includes("user.admin") ? (
                  <button
                    className="btn btn-secondary ms-2"
                    onClick={resetResponseData}
                  >
                    {resetLoader ? "Resetting..." : "Reset"}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* <div
            className="d-flex align-items-center"
            style={{
              marginRight: `${
                tab === 1 ? "25px" : tab === 2 ? "107px" : "135px"
              }`,
            }}
          >
            {tab === 1 && (
              <>
                {selectedLineValue === "main-line" && (
                  <h5 className="mb-0">Main Line Preparation</h5>
                )}
                {selectedLineValue === "crv-line" && (
                  <h5 className="mb-0">CRV Line Preparation</h5>
                )}
                {authInfo.roles?.includes("user.admin") && (
                  <button
                    className={`btn btn-secondary ms-2 ${
                      tab === 1 ? "d-block" : "d-none"
                    }`}
                    onClick={preparePreparationData}
                  >
                    {prepLoader ? "Preparing..." : "Prepare"}
                  </button>
                )}
              </>
            )}


            {tab === 2 && (
              <h5 className="mt-3 pl-2 !important">Main Line Preparation</h5>
            )}
          </div> */}
        </div>
        <>
          {tab === 1 ? (
            <div className="mx-auto" style={{ maxWidth: "1500px" }}>
              <div className="d-flex flex-column flex-lg-row gap-5 gap-lg-3">
                <Table1
                  selectedLineValue={selectedLineValue}
                  productionTargetVal={prodTargetVal}
                  getChassisPreparationData={getChassisPreparationData}
                  chassisData={
                    selectedLineValue === "main-line"
                      ? chassisData
                      : crvChassisData
                  }
                />
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center">
                    {selectedLineValue === "main-line" && (
                      <h5 className="mb-0" style={{ paddingLeft: "18px" }}>
                        Main Line Preparation
                      </h5>
                    )}
                    {selectedLineValue === "crv-line" && (
                      <h5 className="mb-0" style={{ paddingLeft: "18px" }}>
                        CRV Line Preparation
                      </h5>
                    )}
                    {authInfo.roles?.includes("user.admin") && (
                      <>
                        <button
                          className={`btn btn-secondary ms-2 ${
                            tab === 1 ? "d-block" : "d-none"
                          }`}
                          onClick={preparePreparationData}
                        >
                          {prepLoader ? "Preparing..." : "Prepare"}
                        </button>
                        <button
                          className={`btn btn-secondary ms-2 ${
                            tab === 1 ? "d-block" : "d-none"
                          }`}
                          onClick={resetChassisPreparationData}
                        >
                          {resetLoaderTwo ? "Resetting..." : "Reset"}
                        </button>
                      </>
                    )}
                  </div>
                  <ChassisTable
                    chassisData={
                      selectedLineValue === "main-line"
                        ? chassisData
                        : crvChassisData
                    }
                    lineValue={selectedLineValue}
                    handlePostChassis={handlePostChassisFromChassisTable}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="mx-auto" style={{ maxWidth: "1500px" }}>
              <div className="d-flex flex-column flex-lg-row gap-5 gap-lg-4">
                <Preparation />
                <div className="d-flex flex-row flex-lg-column justify-content-center justify-content-lg-start gap-5 gap-lg-4 flex-grow-1">
                  <div>
                    <h5 className="pl-2 !important">Main Line Preparation</h5>
                    <ChassisTable
                      chassisData={chassisData}
                      lineValue={"main-line"}
                      handlePostChassis={handlePostChassisFromChassisTable}
                    />
                  </div>
                  <div>
                    <h5 className="mtt-lg-5 pl-lg-2 !important">
                      CRV Line Preparation
                    </h5>
                    <ChassisTable
                      chassisData={crvChassisData}
                      lineValue={"crv-line"}
                      handlePostChassis={handlePostChassisFromChassisTable}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}
